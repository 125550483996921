// console.log("Vite ⚡️ Rails")

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log(
//   "Visit the guide for more information: ",
//   "https://vite-ruby.netlify.app/guide/rails"
// )

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

import "~/stylesheets/index.css"
import "~/js/index.js"

import ExitModal from "../components/ExitModal.svelte"

const target = document.getElementById("exitModalComponent")

new ExitModal({
  target: target,
  props: {
    siteKey: target.dataset["siteKey"],
    authToken: target.dataset["authToken"],
  },
})
