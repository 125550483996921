<script>
  import { onMount } from "svelte"

  let url = ""
  onMount(() => (url = window.location.href))

  let modalOpen = false
  let exitModalStorage = localStorage.getItem("exitModal")

  let timer = 60

  if (!exitModalStorage) {
    const intervalID = setInterval(countdown, 1000)
    function countdown() {
      if (timer > 0) {
        timer--
      } else {
        clearInterval(intervalID)
      }
    }
  } else {
    document.body.removeEventListener("mouseleave", openModal)
  }

  function openModal() {
    if (!exitModalStorage && timer === 0) {
      modalOpen = true
      document.body.removeEventListener("mouseleave", openModal)
    }
  }

  function closeModal() {
    modalOpen = false
    localStorage.setItem("exitModal", 1)
  }
  export let siteKey
  export let authToken

  function submitModalForm(e) {
    const form = e.target
    form.submit()
    closeModal()
  }
</script>

<svelte:body on:mouseleave={openModal} />

<div
  id="exitModal"
  class="modal-overlay top-0 left-0 w-full h-screen overflow-hidden opacity-0 bg-black bg-opacity-60 transition-opacity ease-linear duration-150 fixed bottom-0 right-0 z-50"
  {modalOpen}
>
  <div
    class="modal-dialog w-[700px] relative my-12 mx-auto max-w-[calc(100%-20px)] min-h-[200px] bg-white rounded-md shadow-sm"
  >
    <div class="heading relative m-0 p-0">
      <div class="wrapped h-16 relative bg-[url('/images/arrow-banner.webp')]">
        <h4
          class="absolute text-white uppercase text-center left-0 right-0 margin-0 text-3xl bottom-3"
        >
          Lock in this special offer now!
        </h4>
        <div
          class="modal-close absolute top-0 right-2 text-xl cursor-pointer text-white"
          on:click={closeModal}
          on:keydown={closeModal}
          role="button"
          tabindex="0"
        >
          <i class="fa-solid fa-xmark" />
        </div>
      </div>
    </div>
    <div class="content flex">
      <div
        class="left-area bg-[url('$images/lf-exit-popup-image.webp')] relative w-1/2 pb-7 bg-center bg-cover bg-no-repeat"
      >
        &nbsp;
      </div>
      <div class="right-area relative bg-emerald-primary w-1/2 pb-2">
        <div class="wrapped white p-5 bg-white text-white">
          <ul class="feature-list ml-5">
            <li>
              <i class="icon fas fa-calendar-days" /><span class="text"
                >Free Month of Service<sup>*</sup></span
              >
            </li>
            <li>
              <i class="icon fas fa-unlock" /><span class="text"
                >Free Lock Box<sup>*</sup></span
              >
            </li>
            <li>
              <i
                class="icon fas fa-truck fa-flip-horizontal"
                style="padding: 0 0 0 15px;"
              /><span class="text">Free Shipping<sup>*</sup></span>
            </li>
            <li>
              <i class="icon fas fa-download" /><span class="text"
                >Free Activation &amp; Equipment</span
              >
            </li>
            <li>
              <i class="icon fas fa-money-bill-alt" /><span class="text"
                >Price Lock Guarantee</span
              >
            </li>
          </ul>
          <p
            class="text-xl mt-2 mb-0 leading-snug text-center text-sky-primary"
          >
            <strong class="uppercase">Receive Your Special Offer</strong>
          </p>
          <p class="text-xl leading-snug text-center text-sky-primary m-0">
            Call Now <strong class=""
              ><a
                href="tel:+1-800-331-9198"
                class="!text-red-secondary hover:!text-red-secondary font-black"
                >1-800-331-9198</a
              ></strong
            >
          </p>
          <p class="text-sm text-red-secondary leading-snug text-center">
            <em>Special Offer Expires Soon</em>
          </p>
        </div>
        <div class="py-2 px-5">
          <p class="text-white text-xl text-center my-2 leading-snug uppercase">
            Or Fill Out This Form
          </p>
          <form
            id="exitModalForm"
            action="/forms/salesforce_api"
            method="post"
            on:submit|preventDefault={submitModalForm}
          >
            <input type="hidden" name="authenticity_token" value={authToken} />
            <input
              type="hidden"
              name="redirect"
              value="https://www.lifefone.com/thankyou.html"
            />
            <input type="hidden" name="referral_source" value={url} />
            <input type="hidden" name="utm_source" value="" /><input
              type="hidden"
              name="utm_content"
              data-field="utm_content"
              value=""
            /><input
              type="hidden"
              name="utm_campaign"
              data-field="utm_campaign"
              value=""
            /><input
              type="hidden"
              name="utm_medium"
              data-field="utm_medium"
              value=""
            />
            <input type="hidden" name="sub_id" value="" />
            <input type="hidden" name="http_referral" value="" />
            <input type="hidden" name="gclid" value="" />
            <input type="hidden" name="lead_source" value="Email Lead" />
            <input type="hidden" name="gclid" value="" />
            <input
              name="first_name"
              type="text"
              id="firstname"
              class="hidden"
            />
            <div class="input-wrapped">
              <input
                type="text"
                name="full_name"
                placeholder="Full Name"
                class="validate-street required-entry"
                autocomplete="name"
                required
              />
            </div>
            <div class="input-wrapped">
              <input
                type="tel"
                name="phone"
                placeholder="Enter Phone #"
                class="validate-phoneLax required-entry"
                autocomplete="tel-national"
                required
              />
            </div>
            <div class="input-wrapped">
              <input
                type="email"
                name="email"
                placeholder="Enter Email"
                class="validate-email required-entry"
                autocomplete="email"
                required
              />
            </div>
            <div class="text-center mt-2">
              <button class="btn btn-primary" type="submit"
                >Get My Offer <i class="fa fa-caret-right" /></button
              >
            </div>
            <div class="flex justify-center mt-2">
              <div class="cf-turnstile" data-sitekey="{siteKey}" data-action="salesforce_api" data-theme="light" id="exitModalTurnstile" data-appearance="interaction-only"></div>
            </div>
            <div class="text-center">
              <span
                class="no-thanks modal-close cursor-pointer text-white text-xs mt-4 underline inline-block"
                on:click={closeModal}
                on:keydown={closeModal}
                role="button"
                tabindex="0">No, Thanks</span
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
